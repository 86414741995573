<template>
  <BCard title="캠페인 정보">
    <BRow>
      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="IDX">
          <BFormInput
            :value="state.campaign.idx"
            readonly
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="상태">
          <BFormSelect
            v-model="state.campaign.state"
            :options="stateOptions"
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup>
          <template #label>
            단계
            <!-- <TagbyTooltip
              text="상시 캠페인의 단계는 어드민에서 수정할 수 없습니다"
              variant="flat-primary"
              icon="InfoIcon"
            /> -->
          </template>
          <BFormSelect
            :value="comptStep"
            :options="stepOptions"
            :disabled="true"
            @input="inputStep"
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="8"
        sm="12"
        cols="12"
      >
        <BFormGroup label="제목">
          <TagbyCopyableInput
            v-model="state.campaign.title"
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="브랜드">
          <TagbyCopyableInput
            v-model="state.campaign.brand_name"
          />
        </BFormGroup>
      </BCol>
    </BRow>

    <hr>

    <BRow>
      <BCol
        v-if="state.campaign.type === 'COMPT'"
        md="8"
        sm="12"
        cols="12"
      >
        <BFormGroup label="SNS">
          <BFormCheckboxGroup
            v-model="state.campaign.sns_channel"
            :options="snsChannelOptions"
            disabled
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="성별">
          <BFormCheckboxGroup
            v-model="state.campaign.gender"
            :options="genderOptions"
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="8"
        sm="12"
        cols="12"
      >
        <BFormGroup label="연령">
          <BFormCheckboxGroup
            v-model="state.campaign.age_group"
            :options="ageOptions"
          />
        </BFormGroup>
      </BCol>
    </BRow>

    <hr>

    <BRow>
      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="등록일">
          <TagbyDatetimeInputV3
            v-model="state.campaign.registered_at"
            disabled
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="선정일">
          <TagbyDatetimeInputV3
            v-model="state.campaign.selection_at"
            :disabled="![null, 'SELECT'].includes(state.campaign.manage_step)"
            :endTime="true"
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="기간">
          <TagbyDateRangeInputV2
            :value="[state.campaign.start_at, state.campaign.end_at]"
            disabled
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup>
          <template #label>
            가이드여부
            <TagbyTooltip
              text="모집중인 상태에서는 가이드여부를 수정할수 없습니다"
              variant="flat-primary"
              icon="InfoIcon"
            />
          </template>
          <BFormCheckbox
            v-model="state.campaign.has_guide"
            :disabled="!updatableHasGuide"
          >
            {{ state.campaign.has_guide ? '가이드배정' : '가이드배정 안 함' }}
          </BFormCheckbox>
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="가이드여부 체크날짜">
          <TagbyDatetimeInputV3
            v-model="state.campaign.has_guide_check_at"
            disabled
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup>
          <template #label>
            가이드 배정 만료일
            <TagbyTooltip
              text="수정하기 위해서는 가이드배정이 체크되어 있어야하며, 현재 단계가 가이드 단계 이어야 합니다"
              variant="flat-primary"
              icon="InfoIcon"
            />
          </template>
          <TagbyDatetimeInputV3
            v-model="state.campaign.guide_pending_at"
            :disabled="!updatableGuidePendingAt"
            :endTime="true"
          />
        </BFormGroup>
      </BCol>
    </BRow>

    <hr>

    <BRow>
      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="상시여부">
          <BFormCheckbox
            v-model="state.campaign.is_endless"
            disabled
          >
            {{ state.campaign.is_endless ? '상시캠페인' : '비상시캠페인' }}
          </BFormCheckbox>
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="미션타입">
          <BFormSelect
            :value="state.campaign.basic_mission_type"
            :options="missionTypeOptions"
            disabled
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="스폰서배너 동의여부">
          <BFormCheckbox
            v-model="state.campaign.has_sponsor_banner"
          >
            {{ state.campaign.has_sponsor_banner ? '동의' : '비동의' }}
          </BFormCheckbox>
        </BFormGroup>
      </BCol>

      <BCol
        md="8"
        sm="12"
        cols="12"
      >
        <BFormGroup label="2차 활용 매체">
          <TagbySelect
            v-model="state.campaign.utilization_channel_list"
            :options="utilizationChannelOptions"
            :reduce="opt => opt.value"
            multiple
          />
        </BFormGroup>
      </BCol>

      <BCol
        md="4"
        sm="6"
        cols="12"
      >
        <BFormGroup label="2차 활용 기간">
          <BInputGroup append="개월">
            <BFormInput
              v-model="state.campaign.utilization_plan_period"
              number
              type="number"
              :disabled="!updatableUtilizationPeriod"
            />
          </BInputGroup>
        </BFormGroup>
      </BCol>
    </BRow>

    <hr>

    <BRow>
      <BCol
        md="2"
        sm="3"
        cols="6"
      >
        <BFormGroup label="대표 이미지">
          <TagbyImgMangerV2
            v-model="state.campaign.thumb_img_obj"
            refererTable="campaign_info"
            :isSaving="isSavingImgFile"
          >
            <div class="text-info mb-1">
              캠페인 저장 버튼을 눌러야 저장이 완료됩니다.
            </div>
          </TagbyImgMangerV2>
        </BFormGroup>
      </BCol>

      <BCol
        md="2"
        sm="3"
        cols="6"
      >
        <BFormGroup label="상세 이미지">
          <TagbyImgMangerV2
            v-model="state.campaign.detail_img_obj"
            refererTable="campaign_info"
            :isSaving="isSavingImgFile"
          >
            <div class="text-info mb-1">
              캠페인 저장 버튼을 눌러야 저장이 완료됩니다.
            </div>
          </TagbyImgMangerV2>
        </BFormGroup>
      </BCol>

      <BCol
        md="8"
        sm="12"
        cols="12"
      >
        <BFormGroup label="참고 사이트">
          <TagbyLinkOpenInput v-model="state.campaign.detail_link" />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
      >
        <BFormGroup label="캠페인 안내">
          <BFormTextarea
            v-model="state.campaign.detail_text"
            rows="5"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
} from "bootstrap-vue"
import TagbyCopyableInput from "@/components/TagbyCopyableInput.vue"
import TagbyDatetimeInputV3 from "@/components/TagbyDatetimeInputV3.vue"
import TagbyDateRangeInputV2 from "@/components/TagbyDateRangeInputV2.vue"
import TagbyImgMangerV2 from "@/components/TagbyImgMangerV2.vue"
import TagbyLinkOpenInput from "@/components/TagbyLinkOpenInput.vue"
import TagbySelect from "@/components/TagbySelect.vue"
import TagbyTooltip from "@/components/TagbyTooltip.vue"
import state from "../../state"
import useInputStep from "../../hooks/useInputStep"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    TagbyCopyableInput,
    TagbyDatetimeInputV3,
    TagbyDateRangeInputV2,
    TagbyImgMangerV2,
    TagbyLinkOpenInput,
    TagbySelect,
    TagbyTooltip,
  },
  setup() {
    const {
      inputStep,
      reduceStep,
    } = useInputStep()

    const stateOptions = [
      { value: "ACTIVE", text: "활성" },
      { value: "WRITING", text: "작성중" },
      { value: "HIDDEN", text: "숨김" },
      { value: "INACTIVE", text: "삭제" },
    ]
    const comptStep = computed(() => reduceStep(state.campaign.step, state.campaign.manage_step))
    const stepOptions = computed(() => [
      { value: "RECRUIT", text: "모집" },
      { value: "GUIDE", text: "가이드" },
      { value: "DELIVERY", text: "배송" },
      { value: "PRE_POSTING", text: "원고검수" },
      { value: "POSTING_RATING", text: "포스팅" },
      { value: "STAT_ESTIMATE", text: "통계" },
      { value: "PROGRESSED", text: "진행완료" },
      { value: "PENDING", text: "보류" },
      { value: "STOP", text: "모집중단" },
      { value: "NOT_MATCHED", text: "NOT_MATCHED(개발자문의필요)", disabled: true },
    ])

    const snsChannelOptions = [
      { value: "INSTAGRAM", text: "인스타" },
      { value: "BLOG", text: "블로그" },
      { value: "INSTAGRAM_REELS", text: "릴스" },
      { value: "YOUTUBE", text: "유튜브" },
      { value: "TIKTOK", text: "틱톡" },
    ]
    const genderOptions = [
      { value: "M", text: "남자" },
      { value: "F", text: "여자" },
    ]
    const ageOptions = [
      "15-19",
      "20-24",
      "25-29",
      "30-34",
      "35-39",
      "40+",
    ]
    const missionTypeOptions = [
      { value: "PRODUCTION", text: "제작" },
      { value: "SHARE", text: "공유" },
      { value: "BOTH", text: "제작+공유" },
    ]
    const updatableHasGuide = computed(() => ![null, "SELECT"].includes(state.campaign.manage_step))
    const updatableGuidePendingAt = computed(() => state.campaign.has_guide && state.campaign.manage_step === "GUIDE")

    const utilizationChannelOptions = computed(() => {
      const presentedChannelList = [
        "SNS",
        "COMMERCE",
        "HOMEPAGE",
        "AD_SNS",
        "AD_ETC",
        "OFFLINE",
      ]
      return [
        { value: "SNS", label: "자사 SNS" },
        { value: "COMMERCE", label: "유통/판매 채널" },
        { value: "HOMEPAGE", label: "자사 홈페이지" },
        { value: "AD_SNS", label: "SNS광고" },
        { value: "AD_ETC", label: "기타광고" },
        { value: "OFFLINE", label: "오프라인" },
        ...(
          (state.campaign.utilization_channel_list ?? [])
            .filter(channel => !presentedChannelList.includes(channel))
            .map(channel => ({ value: channel, label: channel }))
        ),
      ]
    })
    const updatableUtilizationPeriod = computed(() => (
      state.campaign.utilization_channel_list?.length ?? 0) > 0)
    return {
      state,
      stateOptions,
      comptStep,
      stepOptions,
      snsChannelOptions,
      genderOptions,
      ageOptions,
      missionTypeOptions,
      updatableHasGuide,
      updatableGuidePendingAt,
      utilizationChannelOptions,
      updatableUtilizationPeriod,
      inputStep,
    }
  },
})
</script>
