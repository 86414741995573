<template>
  <a
    v-b-tooltip.hover
    :title="text"
    :variant="variant"
    class="btn-icon rounded-circle"
  >
    <FeatherIcon
      v-if="icon != null"
      :icon="icon"
    />
  </a>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BButton,
  VBTooltip,
} from "bootstrap-vue"

export default defineComponent({
  components: {
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
    variant: {
      type: String,
    },
  },
  setup() {

  },
})
</script>
