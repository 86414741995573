<template>
  <TagbyActionCardV2>
    <CreateButton />
    <CreateMemoButton />
    <DivHeader text="test" />
    <TestButton />
  </TagbyActionCardV2>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionCardV2 from "@/components/TagbyActionCardV2.vue"
import CreateButton from "./CreateButton.vue"
import CreateMemoButton from "./CreateMemoButton.vue"
import TestButton from "./TestButton.vue"
import DivHeader from "@/views/campaign/components/DivHeader.vue"

export default defineComponent({
  components: {
    TagbyActionCardV2,
    CreateButton,
    CreateMemoButton,
    TestButton,
    DivHeader,
  },
  setup() {

  },
})
</script>
