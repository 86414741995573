<template>
  <TagbySidebarWithButton
    :visible="state.createMemoSidebar.isVisible"
    :isSaving="state.createMemoSidebar.isSaving"
    :isValid="true"
    title="메모"
    width="30%"
    @ok="createMemo"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="내용">
      <BFormTextarea v-model="state.createMemoSidebar.text" />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormTextarea,
} from "bootstrap-vue"
import TagbySidebarWithButton from "@/components/TagbySidebarWithButton.vue"
import TagbyInputRow from "@/components/TagbyInputRow.vue"
import state from "../../state"
import useCreateMemoSidebar from "../../hooks/useCreateMemoSidebar"

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormTextarea,
  },
  setup() {
    const {
      turnOffSidebar,
      createMemo,
    } = useCreateMemoSidebar()

    return {
      state,
      turnOffSidebar,
      createMemo,
    }
  },
})
</script>
