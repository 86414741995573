<template>
  <CreateMemoSidebar />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import CreateMemoSidebar from "./CreateMemoSidebar.vue"

export default defineComponent({
  components: {
    CreateMemoSidebar,
  },
  setup() {

  },
})
</script>
