<template>
  <div>
    <BButton
      variant="outline-primary"
      @click="turnOnSidebar"
    >
      <FeatherIcon icon="ImageIcon" />
    </BButton>

    <TagbySidebarWithButton
      :title="title"
      :visible="isVisibleSidebar"
      :isValid="uploadedImgObj != null"
      :isSaving="isSaving"
      @hidden="turnOffSidebar"
      @cancel="turnOffSidebar"
      @ok="$emit('ok', uploadedImgObj)"
    >
      <TagbyOverlay
        :show="isUploading"
      >
        <BRow>
          <BCol>
            <BFormGroup>
              <BButton
                variant="outline-primary"
                class="btn-icon"
                @click="wakeUpFileInput"
              >
                <FeatherIcon icon="UploadIcon" />
                업로드
                <BFormFile
                  :id="fileId"
                  type="file"
                  accept="image/*"
                  style="display:none"
                  @input="inputImgFile"
                />
              </BButton>
              <BButton
                variant="outline-primary"
                class="btn-icon mr-1"
                @click="downloadImg"
              >
                <FeatherIcon icon="DownloadIcon" />
                다운로드
              </BButton>
            </BFormGroup>
          </BCol>
        </BRow>
        <BRow>
          <BCol>
            <BFormGroup>
              <BImg
                v-if="imgSrc"
                :src="imgSrc"
                fluid
              />
            </BFormGroup>
          </BCol>
        </BRow>
        <slot />
      </TagbyOverlay>
    </TagbySidebarWithButton>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
} from "@vue/composition-api"
import {
  BButton,
  BImg,
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
} from "bootstrap-vue"
import { v4 as uuid4 } from "uuid"
import TagbySidebarWithButton from "./TagbySidebarWithButton.vue"
import TagbyOverlay from "./TagbyOverlay.vue"
import downloadFile from "@/utils/downloadFile"
import uploadFile from "@/utils/uploadFile"
import useToast from "@/utils/toast"

export default defineComponent({
  components: {
    BButton,
    BImg,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    TagbySidebarWithButton,
    TagbyOverlay,
  },
  model: {
    event: "ok",
    prop: "imgObj",
  },
  props: {
    title: {
      type: String,
    },
    imgObj: {
      type: Object,
      default: () => ({
        idx: null,
        url: null,
        name: null,
      }),
    },
    isSaving: {
      type: Boolean,
    },
    refererTable: {
      type: String,
    },
  },
  emits: [
    "ok",
  ],
  setup(props) {
    const uploadedImgObj = ref()
    const isVisibleSidebar = ref(false)
    const turnOnSidebar = () => {
      isVisibleSidebar.value = true
    }
    const turnOffSidebar = () => {
      isVisibleSidebar.value = false
    }

    const downloadImg = () => {
      if (uploadedImgObj.value != null) {
        downloadFile(uploadedImgObj.value.url, uploadedImgObj.value.name)
      } else {
        downloadFile(props.imgObj.url, props.imgObj.name)
      }
    }

    const isUploading = ref(false)
    const fileId = `__${uuid4()}`

    const imgSrc = computed(() => {
      if (uploadedImgObj.value != null) {
        return uploadedImgObj.value.url
      }
      if (props.imgObj != null) {
        return props.imgObj.url
      }
      return null
    })

    const wakeUpFileInput = () => {
      document.getElementById(fileId).click()
    }

    const { makeToast } = useToast()
    const inputImgFile = file => {
      isUploading.value = true
      uploadFile(file, props.refererTable, "")
        .then(result => {
          uploadedImgObj.value = {
            idx: result.idx,
            url: result.file_url,
            name: result.file_name,
          }
        }).catch(() => {
          makeToast("danger", "이미지를 업로드하는데 실패했습니다")
        }).finally(() => {
          isUploading.value = false
        })
    }

    return {
      isVisibleSidebar,
      turnOnSidebar,
      turnOffSidebar,

      downloadImg,

      uploadedImgObj,
      isUploading,

      fileId,
      imgSrc,

      wakeUpFileInput,
      inputImgFile,
    }
  },
})
</script>
