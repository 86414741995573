import useToast from "@/utils/toast"
import state from "../state"
import api from "../api"
import useErrorReasonModal from "./useErrorReasonModal"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErrorReasonModal()

  const getAdminMe = () => api.getAdminMe().then(response => {
    state.adminPermission = response.data.data.permission
  })

  const getDetail = () => api.getDetail({
    group_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.member_type = resData.member_type
    state.member_idx = resData.member_idx
    state.state = resData.state
    state.name = resData.name
    state.sender_list = resData.sender_list
    state.message_template = resData.message_template
    state.utc_offset = resData.utc_offset
    state.available_start_time = resData.available_start_time
    state.available_end_time = resData.available_end_time
    state.created_at = resData.created_at
  }).catch(err => {
    state.hasLoadError = true
    turnOnErrorReasonModal(err)
  })

  const getTimelineList = () => api.getTimelineList({
    info_table: "campaign_info",
    info_idx: state.idx,
  }).then(response => {
    state.timelineList = response.data.data
  }).catch(err => {
    makeToast("danger", "타임라인을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  return {
    getAdminMe,
    getDetail,
    getTimelineList,
  }
}
