<template>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(campaign)>
          <CampaignCard />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV3
        v-if="!getters.isCreatingMode"
        :timelineList="state.timelineList"
      />
    </template>

    <template #hidden>
      <Sidebars />
      <Modals />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import { getRouterIdx } from "@/utils/router"
import TagbyTabs from "@/components/TagbyTabs.vue"
import TagbyDetailLayout from "@/components/TagbyDetailLayout.vue"
import TagbyTimelinesV3 from "@/components/TagbyTimelinesV3.vue"
import ActionCard from "./components/ActionCard/Main.vue"
import CampaignCard from "./components/CampaignCard"
import Sidebars from "./components/Sidebars"
import Modals from "./components/Modals"
import useFetch from "./hooks/useFetch"
import state from "./state"
import getters from "./getters"
import useState from "./hooks/useState"

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    ActionCard,
    TagbyTimelinesV3,
    CampaignCard,
    Sidebars,
    Modals,
  },
  setup() {
    const { resetState } = useState()
    resetState()

    state.idx = getRouterIdx()

    const { getAdminMe, getDetail, getTimelineList } = useFetch()

    if (!getters.isCreatingMode) {
      getAdminMe()
      getDetail()
      getTimelineList()
    }

    const tabList = [
      { key: "campaign", title: "캠페인" },
      { key: "mission", title: "미션", visible: !getters.isCreatingMode },
      { key: "apply", title: "신청자", visible: !getters.isCreatingMode },
      { key: "guide", title: "가이드", visible: !getters.isCreatingMode },
      { key: "delivery", title: "배송", visible: !getters.isCreatingMode },
      { key: "pre_posting", title: "원고", visible: !getters.isCreatingMode },
      { key: "invoice", title: "정산", visible: !getters.isCreatingMode },
      { key: "etc", title: "기타", visible: !getters.isCreatingMode },
    ]

    return {
      tabList,
      state,
      getters,
    }
  },
})
</script>
