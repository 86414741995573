import {
  computed,
} from "@vue/composition-api"

import state from "../state"

export default () => {
  const turnOnModal = () => {
    state.changeStepModal.isVisible = true
  }
  const turnOffModal = () => {
    state.changeStepModal.isVisible = false
  }
  const reduceStep = (step, manageStep) => {
    if (step === "PROGRESSED") {
      return "PROGRESSED"
    }
    if (step === "STOP") {
      return "STOP"
    }
    if (step === "PENDING") {
      return "PENDING"
    }
    if (manageStep == null || manageStep === "SELECT") {
      return "RECRUIT"
    }
    if (manageStep === "GUIDE") {
      return "GUIDE"
    }
    if (manageStep === "DELIVERY") {
      return "DELIVERY"
    }
    if (manageStep === "PRE_POSTING") {
      return "PRE_POSTING"
    }
    if (manageStep === "POSTING_RATING") {
      return "POSTING_RATING"
    }
    if (manageStep === "STAT_ESTIMATE") {
      return "STAT_ESTIMATE"
    }
    return "NOT_MATCHED"
  }
  const revertStep = () => {
    state.campaign.step = state.changeStepModal.prevStep
    state.campaign.manage_step = state.changeStepModal.prevManageStep
  }
  const changeStep = () => {
    const value = state.changeStepModal.inputValue
    if (value === "RECRUIT") {
      state.campaign.step = "RECRUIT"
      state.campaign.manage_step = "SELECT"
    } else if (value === "GUIDE") {
      state.campaign.step = "SELECTED"
      state.campaign.manage_step = "GUIDE"
    } else if (value === "DELIVERY") {
      state.campaign.step = "SELECTED"
      state.campaign.manage_step = "DELIVERY"
    } else if (value === "PRE_POSTING") {
      state.campaign.step = "SELECTED"
      state.campaign.manage_step = "PRE_POSTING"
    } else if (value === "POSTING_RATING") {
      state.campaign.step = "SELECTED"
      state.campaign.manage_step = "POSTING_RATING"
    } else if (value === "STAT_ESTIMATE") {
      state.campaign.step = "SELECTED"
      state.campaign.manage_step = "STAT_ESTIMATE"
    } else if (value === "PROGRESSED") {
      state.campaign.step = "PROGRESSED"
      state.campaign.manage_step = "STAT_ESTIMATE"
    } else if (value === "PENDING") {
      state.campaign.step = "PENDING"
    }
  }

  const savedReducedStep = computed(() => reduceStep(
    state.savedCompt.step,
    state.savedCompt.manage_step,
  ))

  const inputStep = value => {
    if (state.isLoadingCompt) {
      return
    }
    state.changeStepModal.prevStep = state.campaign.step
    state.changeStepModal.prevManageStep = state.campaign.manage_step
    state.changeStepModal.inputValue = value
    changeStep()

    if (savedReducedStep.value !== value) {
      turnOnModal()
    }
  }

  return {
    turnOnModal,
    turnOffModal,
    reduceStep,
    revertStep,
    inputStep,
    changeStep,
  }
}
