<template>
  <BInputGroup>
    <BFormInput
      :value="value"
      :disabled="disabled"
      @input="$emit('input', $event)"
    />
    <BInputGroupAppend
      is-text
      class="cursor-pointer invert-button"
      @click="openPage"
    >
      <FeatherIcon
        icon="ExternalLinkIcon"
      />
    </BInputGroupAppend>
  </BInputGroup>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input',
  ],
  setup(props) {
    const openPage = () => {
      if (props.value) {
        window.open(props.value, '_blank')
      }
    }
    return {
      openPage,
    }
  },
})
</script>

<style>
  .invert-button:hover {
    filter: invert(100%)
  }
</style>
