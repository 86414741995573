export default () => ({
  hasLoadError: false,
  adminPermission: null,
  timelineList: [],
  idx: null,
  campaign: {
    idx: null,
    state: null,
    step: null,
    manage_step: null,
    title: null,
    brand_name: null,
    gender: null,
    age_group: null,
    registered_at: null,
    selection_at: null,
    start_at: null,
    end_at: null,
    has_guide: null,
    has_guide_check_at: null,
    guide_pending_at: null,
    is_endless: null,
    basic_mission_type: null,
    has_sponsor_banner: null,
    utilization_channel_list: null,
    utilization_plan_period: null,
    detail_link: null,
    detail_text: null,
  },
  adv: {},
  applyList: [],
  rewardList: [],
  itemList: [],
  questionList: [],
  missionList: [],
  claimList: [],
  brandPartnerList: [],
  guideList: [],
  deliveryList: [],
  addressList: [],
  prePostingList: [],
  invoiceList: [],
  chatRoomList: [],
  chatList: [],
  chatFileList: [],
  isSaving: false,
  errorReasonModal: {
    isVisible: false,
    errorReason: null,
  },
  createMemoSidebar: {
    isVisible: false,
    isSaving: false,
    text: null,
  },
  changeStepModal: {
    isVisible: false,
    prevStep: null,
    prevManageStep: null,
    inputValue: null,
  },
})
